
  import { Component, Vue } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { PurchaseOrder } from '@/entities/purchase'
  import { mapGetters } from 'vuex'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import dayjs from 'dayjs'

@Component({
  computed: {
    ...mapGetters('app', ['system']),
  },
})
  export default class consignmentPDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  documentType: Record<string, any>
  document: Record<string, any>
  template: Record<string, any>
  disabledPdf = true
  fields: Record<string, any>
  idProcessDocument = null
  idProcess = null

  system!: string

  async mounted () {
    const { item: { id } } = this
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id } },
      force: true,
    })
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
      force: true,
    })

    this.idProcess = process[0].id
    const processDocument = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'document' } },
      force: true,
    })

    this.idProcessDocument = processDocument[0].id

    this.documentType = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { name: { _eq: 'consignment_contract' } },
      force: true,
    }))[0]

    this.document = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        id_process_record: { _eq: this.purchaseOrder.id },
        id_document_type: { _eq: this.documentType.id },
      },
      force: true,
    })).sort((prev, next) => {
      if (dayjs(prev.createdAt).isBefore(dayjs(next.createdAt))) {
        return 1
      } else if (dayjs(prev.createdAt).isAfter(dayjs(next.createdAt))) {
        return -1
      }
      return 0
    })[0]

    this.disabledPdf = this.purchaseOrder.acquisitionType.name !== 'consignment' || !this.document
  }

  async getFile (document) {
    const status = ['consignment_active', 'active']
    if (status.includes(this.purchaseOrder.status.name)) return false

    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document?.id } },
          { id_process_record: { _eq: this.purchaseOrder.id } },
        ],
      },
      force: true,
    }))[0]

    if (!existDocument?.id) return false
    const fileProcess = (await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: existDocument?.id } }, { parameter: { process: { id: { _eq: this.idProcessDocument } } } }, { parameter: { name: { _eq: 'consignment_contract' } } }] },
      force: true,
    }))[0]

    const fileParameter = (await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { name: { _eq: 'consignment_contract' } },
      force: true,
    }))[0]

    if (fileParameter && fileProcess?.file && !this.purchaseOrder?.status?.isActive) {
      window.open(fileProcess?.file?.uri)
      this.loading = false
      return true
    }
  }

  async generatePDF () {
    this.loading = true
    // Revisa si existe un documento asociado a la consignacion
    const document = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'consignment_contract' } }, { process: { id: { _eq: this.idProcess } } }] },
      force: true,
    }))

    if (await this.getFile(document)) return

    // Si no hay documento, crea uno nuevo
    this.template = this.documentType.template

    this.purchaseOrder.negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id: this.purchaseOrder.negotiation.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal.stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { deals: { id: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } } },
      force: true,
    })

    await this.getFields()

    const pdfContent = this.$refs.pdfContent
    const element = pdfContent instanceof HTMLElement ? pdfContent : (pdfContent as Vue).$el as HTMLElement

    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a4')
    this.setFont(doc)

    // Coordenadas iniciales
    const x = 10
    const y = 20

    doc.setLineWidth(400)

    // Llenar el PDF con el contenido
    await this.page1(doc, y, x)

    // Convertir el contenido del HTML a texto en el PDF
    this.addHtmlContent(doc, element, x, y)
  }

  async getPaymentOrderItems (id) {
    const paymentOrder = (await this.fetchData({
      query: { name: 'find', model: 'PaymentOrder' },
      filter: { id_deal: { _eq: id } },
      force: true,
    }))[0]

    const ticketItem = paymentOrder.items.filter(item => item.processExpense.expense.name === 'traffic_ticket_payment')[0]
    const carItem = paymentOrder.items.filter(item => item.processExpense.expense.name === 'auto_payment')[0]
    const retentionItem = paymentOrder.items.filter(item => item.processExpense.expense.name === 'retention')[0]
    const debtItem = paymentOrder.items.filter(item => item.processExpense.expense.name === 'debt_payment')[0]

    return { ticketItem, carItem, retentionItem, debtItem }
  }

  getItemTotalCost (item) {
    const pendingStatuses = ['pending', 'pending_payment']

    let itemCost = 0
    const recipients = item?.recipients
    if (!recipients) return itemCost
    recipients?.forEach(recipient => {
      const { payments } = recipient

      payments?.filter(payment => {
        return pendingStatuses.includes(payment?.status?.name)
      })?.forEach(payment => {
        itemCost += payment.amount
      })
    })
    return itemCost
  }

  getDiscountsText (itemsCost, retentionComments) {
    const { ticket, retention, debt } = itemsCost
    if (ticket === 0 && retention === 0 && debt === 0) return ''
    let text = 'se descontarán los montos de: '
    const values = [
      {
        description: 'multas',
        value: ticket,
      },
      {
        description: 'retenciones',
        value: retention,
        complements: retentionComments,
      },
      {
        description: 'prenda',
        value: debt,
      },
    ].filter(item => item.value !== 0)
    const textParts = values.map(item => {
      let itemText = `${fixPrice(item.value)} por motivo de ${item.description}`
      if (item.complements) {
        const complementsText = ` (${item.complements.join(', ')})`
        itemText += complementsText
      }
      return itemText
    })
    text += textParts.join(', ') + (textParts.length > 0 ? '.' : '')

    return text
  }

  async getPaymentText (...items) {
    const textFragments = []
    for (const item of items) {
      const recipients = item?.recipients || []
      await Promise.all(recipients?.map(async recipient => {
        const { person, payments } = recipient

        await Promise.all(payments.map(async payment => {
          const { recipientAccount } = payment
          const accountNumber = recipientAccount?.bankData?.accountNumber
          const bank = recipientAccount ? await this.fetchData({
            query: { name: 'fetch', model: 'Person', params: { id: recipientAccount.bankData.bank.id } },
            force: true,
          }) : undefined
          const bankName = bank?.companyName?.toUpperCase()
          textFragments.push(`${person.companyName || person.fullName}, RUT ${person.uid} mediante ${payment.type.description}${recipientAccount ? `, a la cuenta ${accountNumber} del banco ${bankName}` : ''}, por el monto de ${fixPrice(payment.amount)}, y respaldo al correo electrónico ${person?.email?.personal || person?.email?.work || 'NO INFORMADO'}`)
        }))
      }))
    }
    return textFragments.join('; ')
  }

  async getFields () {
    const company = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
      force: true,
    }))[0]
    const companyAddress = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: company.id } },
      force: true,
    }))[0]
    const { address } = companyAddress

    const chassisSerialComponent = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: {
        slug: { _eq: 'chassis_serial' },
      },
      force: true,
    }))[0]

    const chassisSerial = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        id_component: { _eq: chassisSerialComponent.id },
        id_deal: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id },
      },
      force: true,
    }))[0]

    const {
      carItem,
      ticketItem,
      retentionItem,
      debtItem,
    } = await this.getPaymentOrderItems(this.purchaseOrder.negotiation.inspection.appraisal.deal.id)

    const itemsCost = {
      car: this.getItemTotalCost(carItem),
      ticket: this.getItemTotalCost(ticketItem),
      retention: this.getItemTotalCost(retentionItem),
      debt: this.getItemTotalCost(debtItem),
    }

    const retentionComments = retentionItem
      ?.recipients
      ?.map(recipient => recipient.payments)
      ?.flat()
      ?.map(payment => payment.comment)

    const owner = this.purchaseOrder.negotiation.inspection.deal.ownership.owners[0].person

    const consginmentAccountsIds = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
      force: true,
    }))[0].config.consignment.id_financial_account_income

    const financialAccounts = await Promise.all(consginmentAccountsIds.map(async id => {
      const account = await this.fetchData({
        query: { name: 'find', model: 'FinancialAccount' },
        filter: { id: { _eq: id } },
        force: true,
      })
      return account[0]
    }))

    const companyPersonAccounts = await Promise.all(financialAccounts.map(async account => {
      const personAccount = await this.fetchData({
        query: { name: 'find', model: 'PersonAccount' },
        filter: { id: { _eq: account.idPersonAccount } },
        force: true,
      })
      return personAccount[0]
    }))

    const accountPerson = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: {
        accounts: {
          id: { _eq: companyPersonAccounts[0].id },
        },
      },
      force: true,
    }))[0]

    let companyAccount = companyPersonAccounts.length > 1 ? 'las cuentas ' : 'la cuenta '
    if (companyPersonAccounts.length > 1) {
      companyPersonAccounts.forEach((account, index) => {
        companyAccount += `${account.bankData.accountNumber}, ${account.bankData.bank.alias}${index + 1 === companyPersonAccounts.length ? '' : '; '}`.toUpperCase()
      })
    } else {
      const account = companyPersonAccounts[0]
      companyAccount += account ? `${account.bankData.accountNumber}, ${account.bankData.bank.alias}`.toUpperCase() : 'NO INFORMADO'
    }

    const saleRepresentatives = this.document.interveners
      .filter(intervener => intervener.field.name === 'sale_representative')
    const representativeText = 'representado por ' + saleRepresentatives
      .map((representative, index) => {
        return `${representative.person.fullName}, RUT ${representative.person.uid}${index < saleRepresentatives.length - 1 ? (index < saleRepresentatives.length - 2 ? ';' : ' y') : ''}`
      })
      .join(' ')

    this.fields = {
      representative_text: representativeText,
      city: `${address.city.name}, región ${address.city.region.name}`.toUpperCase(),
      start_contract: 'las ' + dayjs(this.document.date).add(this.utcDiff, 'minute').format('HH:mm, DD/MM/YYYY'),
      auto: `${this.document.metadata.auto} año ${this.document.metadata.year} patente ${this.document.metadata.ppu}`,
      inspection: this.purchaseOrder.negotiation.inspection.id,
      chassis_serial: chassisSerial?.value || 'NO INFORMADO',
      mileage: fixAmountFormatted(this.document.metadata.mileage),
      company_name: `${company.companyName}, rol ${company.uid}`.toUpperCase(),
      company_address: `${address.streetType.description} ${address.streetName} ${address.streetNumber}`.toUpperCase(),
      company_account: companyAccount,
      company_person_account_name: (accountPerson.companyName || accountPerson.fullName).toUpperCase(),
      company_person_account_uid: accountPerson.uid,
      company_email: company?.email?.personal || company?.email?.work || 'NO INFORMADO',
      seller_name: owner.fullName,
      seller_uid: owner.uid,
      agreed_price: fixPrice(this.document.metadata.agreementAmount),
      discounts_text: this.getDiscountsText(itemsCost, retentionComments),
      consignment_period: this.document.metadata.consignment_period?.days || 2,
      consignment_expiracy_date: dayjs(this.document.date).add(this.document.metadata.consignment_period?.days || 2, 'day').format('DD/MM/YYYY'),
      early_withdrawal_penalty: fixPrice(this.document.metadata.early_withdrawal_penalty.amount),
      non_withdrawal_cost: fixPrice(this.document.metadata.non_withdrawal_cost.amount),
      withdrawal_period: this.document.metadata.withdrawal_period?.days + ' días',
      payment_text: await this.getPaymentText(
        carItem,
        ticketItem,
        retentionItem,
        debtItem
      ),
      system: this.system,
    }
  }

  get utcDiff () {
    return dayjs().utcOffset()
  }

  replaceVariable (text, variableName, value) {
    return text.split(`$${variableName}$`).join(value).split('\'').join('"')
  }

  findVariableNames (text) {
    const variables = []
    text.split(' ').forEach(word => {
      const splitted = word.split('')
      if (splitted[0] === '$') {
        variables.push(word.split('$')[1])
      }
    })
    return variables
  }

  addIntroduction (doc, x, y) {
    const { paragraphs } = this.template

    paragraphs.forEach(paragraph => {
      const variables = this.findVariableNames(paragraph)
      let newText = paragraph

      variables.forEach(variable => {
        newText = this.replaceVariable(newText, variable, this.fields[variable])
      })

      y = this.addParagraph(doc, x, y, newText, 187, false)
    })

    return y
  }

  async addList (doc, x, y) {
    const { list } = this.template
    for (const [index, item] of list.entries()) {
      const variables = this.findVariableNames(item.text)
      let newText = item.text
        .split(' y en las condiciones indicadas en el informe de inspección N° $inspection$')
        .join(this.fields.inspection ? ' y en las condiciones indicadas en el informe de inspección N° $inspection$' : '')

        .split(', se descontará el valor de multa $traffic_ticket_payment$')
        .join(this.fields.traffic_ticket_payment !== '$0' ? ', se descontará el valor de multa $traffic_ticket_payment$' : '')

        .split(', momento desde el cual el consignante tendrá $withdrawal_period$ para retirar su vehículo sin recargos, posterior a este periodo, el consignatario efectuará el cobro de $non_withdrawal_cost$ diarios por concepto de estacionamiento')
        .join(this.fields.non_withdrawal_cost !== '$0'
          ? ', momento desde el cual el consignante tendrá $withdrawal_period$ para retirar su vehículo sin recargos, posterior a este periodo, el consignatario efectuará el cobro de $non_withdrawal_cost$ diarios por concepto de estacionamiento'
          : '')

        .split('El consignante acepta que al retirar el vehículo antes del día $consignment_expiracy_date$, deberá pagar una tarifa de gestión de $early_withdrawal_penalty$ como compensación por los servicios prestados por el consignatario, pago que debe ser realizado previo al retiro, por transferencia electrónica a $company_account$, de $company_person_account_name$, RUT $company_person_account_uid$ y el respaldo al correo electrónico $company_email$.')
        .join(this.fields.early_withdrawal_penalty !== '$0'
          ? 'El consignante acepta que al retirar el vehículo antes del día $consignment_expiracy_date$, deberá pagar una tarifa de gestión de $early_withdrawal_penalty$ como compensación por los servicios prestados por el consignatario, pago que debe ser realizado previo al retiro, por transferencia electrónica a $company_account$, de $company_person_account_name$, RUT $company_person_account_uid$ y el respaldo al correo electrónico $company_email$.'
          : 'El consignante no deberá pagar ningún costo por concepto de gestión, publicación o limpieza de su vehículo, siendo esto un servicio de cortesía.')

      variables.forEach(variable => {
        newText = this.replaceVariable(newText, variable, this.fields[variable])
      })

      newText = newText
        .split(' ,').join(',')
        .split(' .').join('. ')

      if (y > doc.internal.pageSize.getHeight() - 65) {
        y = this.newPage(doc, y)
        await this.addClosingSection(doc)
      }

      this.addBoldText(doc, x + 4, y, `${index + 1}. `)

      // Aquí ajustamos el margen izquierdo y el ancho máximo
      y = this.applyTextWithStyles(doc, x + 12, y, `|b|${item.title}|/b| ${newText}`) + 3
    }

    return y + 20
  }

  addClosure (doc: jsPDF, x: number, y: number): number {
    const { closure } = this.template
    y = this.addParagraph(doc, x, y, closure, 187, false)
    return y
  }

  async addSignSection (doc: jsPDF, y) {
    const owner = this.purchaseOrder.negotiation.inspection.deal.ownership.owners[0].person
    const saleRepresentatives = this.document.interveners
      .filter(intervener => intervener.field.name === 'sale_representative')

    let height = (y + doc.internal.pageSize.getHeight() - 30) / 2

    if (saleRepresentatives.length === 1) {
      const saleRepresentative = saleRepresentatives[0].person

      const declaration = `Firma del ${saleRepresentative ? 'representante' : 'propietario'}`
      const ownerName = saleRepresentative ? saleRepresentative.fullName : owner.fullName
      const ownerUid = saleRepresentative ? saleRepresentative.uid : owner.uid

      doc.setDrawColor('#0033A5')
      doc.line(60, height, doc.internal.pageSize.getWidth() - 60, height)
      height += 5
      doc.text(declaration, this.getCenterWidth(doc, declaration), height)
      height += 5
      doc.setFont(undefined, 'bold')
      doc.text(ownerName, this.getCenterWidth(doc, ownerName), height)
      height += 5
      doc.text(ownerUid, this.getCenterWidth(doc, ownerUid), height)
      height += 7
    } else {
      const pairs = []
      const offsetX = 45
      saleRepresentatives.forEach((representative, index) => {
        if (index % 2 === 0) {
          pairs.push([])
        }
        pairs[pairs.length - 1].push(representative.person)
      })
      pairs.forEach(pair => {
        pair.forEach((representative, index) => {
          const declaration = `Firma del ${representative ? 'representante' : 'propietario'}`
          const ownerName = representative ? representative.fullName : owner.fullName
          const ownerUid = representative ? representative.uid : owner.uid

          const offsetMultiplier = index === 0 ? -1 : 1
          const offset = offsetX * offsetMultiplier

          doc.setDrawColor('#0033A5')
          doc.line((60 + offset), height, doc.internal.pageSize.getWidth() - 60 + offset, height)
          height += 5
          doc.text(declaration, this.getCenterWidth(doc, declaration) + offset, height)
          height += 5
          doc.setFont(undefined, 'bold')
          doc.text(ownerName, this.getCenterWidth(doc, ownerName) + offset, height)
          height += 5
          doc.text(ownerUid, this.getCenterWidth(doc, ownerUid) + offset, height)
          height += 7
          this.setDefaultFont(doc)
          if (index === 0) {
            height -= 22
          }
        })
      })
    }
    return height
  }

  async addClosingSection (doc: jsPDF) {
    const executive = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.executive
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })
    this.addClosing(doc, [
        'Asesor comercial:',
        `${executive.person.firstName} ${executive.person.surname}`,
        `Tel: ${executive.person.phoneWork || 'No informado'}`,
        executive.person.email.work || 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  async page1 (doc: jsPDF, y, x) {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = await this.loadImage(system.photo)
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      doc.addImage(imageData, 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }

    this.footer(doc)
    await this.addClosingSection(doc)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, this.template.title) + 3
    y = this.addIntroduction(doc, x, y)
    y = await this.addList(doc, x, y)
    y = this.addClosure(doc, x, y)
    await this.addSignSection(doc, y)
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`consignacion ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.fullName} ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  findComponentInfo (component, inspection) {
    const matchingInspectedComponent = inspection.inspectedComponents?.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )
    if (!matchingInspectedComponent) return
    const date = DateGenerator.findGeneratedDate(matchingInspectedComponent.findInspectionParameterByOrder(2).value).internal

    const name = matchingInspectedComponent.findInspectionParameterByOrder(1).value

    return { date, name }
  }

  findComponentBySlug (inspectionComponents, slug) {
    return inspectionComponents?.find(component => component.slug === slug)
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
